/*jshint esversion: 6 */

// libraries
import 'expose-loader?$!expose-loader?jQuery!jquery';
import 'expose-loader?$!expose-loader?bootstrap';

// JS
import '../public/style/libs/bootstrap-3.3.7-dist/js/bootstrap';
import '../public/js/theme/App';
import '../public/js/libs/jquery-validation/jquery.validate';
import '../public/js/libs/jquery-validation/additional-methods';
import '../public/js/libs/inputmask/jquery.inputmask.bundle';
import '../public/js/theme/AppNavigation';
import '../public/js/theme/AppForm';
import '../public/js/libs/bootstrap-datepicker/bootstrap-datepicker';
// var FroalaEditor = require('../public/js/libs/froala-editor/js/froala_editor.min.js');
import FroalaEditor from "../public/js/libs/froala-editor/js/froala_editor.pkgd.min.js";
import "../public/js/libs/froala-editor/css/froala_editor.pkgd.min.css";
import 'bootstrap-timepicker';

import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import uiRouter from 'angular-ui-router';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);
dayjs.extend(dayjsCustomParseFormat);

import 'ngstorage';
import 'angular-filter';
import moment from 'moment';
import 'moment-timezone';
import { Formio } from 'formiojs';

// Styles
import '../public/style/libs/FontAwesome/css/all.css';
import '../public/style/libs/bootstrap-3.3.7-dist/css/bootstrap.min.css';
import '../public/style/libs/bootstrap-3.3.7-dist/css/bootstrap-dialog.min.css';
import '../app/app.css';
import '../public/style/theme.css';
import '../public/style/form-wizard.css';
import '../public/style/theme-iconic-font.css';
import '../public/style/util.css';
import '../public/style/libs/bootstrap-datepicker/datepicker3.css';
import '../public/js/libs/froala-editor/css/froala_editor.pkgd.min.css';
import '../public/style/libs/Formio/formio.full.min.css';
import 'bootstrap-timepicker/css/bootstrap-timepicker.css';


import 'angular-ui-bootstrap';
import 'angular-file-upload';

// first-party modules
import allevaApi from './modules/alleva-api';

// first party service
import logService from './modules/log-service';
import gClassroom from './modules/google-classroom-service';
import siteStyle from './modules/site-style';
import allevaSig from './modules/signature';
import allevaAutosave from './modules/alleva-autosave-service';
import darkDeploy from './modules/dark-deploy-service';
import calculateFields from './modules/calculate-fields';
import fileUpload from './modules/file-upload';
import advancedFormSignature from './modules/advanced-form-signature';

// controllers
import FamilyPortalApp from './controllers/FamilyPortalApp.controller';
import LoginController from './controllers/Login.controller';
import ResetPasswordController from './controllers/ResetPassword.controller';
import MyCalendarController from './controllers/MyCalendar.controller';
import HomeController from './controllers/Home.controller';
import FormIOController from './controllers/FormIO.controller';
import FormController from './controllers/Form.controller';
import TreatmentPlanController from './controllers/TreatmentPlan.controller';
import AdvancedFormController from  './controllers/AdvancedForm.controller';

// routes
import routes from './routes';

// controller extensions
import InboxController from './controllers/extensions/Inbox.controller';
import PaginationController from './controllers/extensions/pagination';

// main app and dependencies
const FamilyPortal = angular.module('FamilyPortal', [allevaApi, uiRouter, 'ngStorage', ngSanitize, ngAnimate, 'angular.filter', 'ui.bootstrap', 'angularFileUpload', allevaSig]);

// Filters
FamilyPortal.filter('tel', function () {
  return function (tel) {
      if (!tel) { return ''; }

      var value = tel.toString().trim().replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
          return tel;
      }

      var country, city, number;

      switch (value.length) {
          case 10: // +1PPP####### -> C (PPP) ###-####
              country = 1;
              city = value.slice(0, 3);
              number = value.slice(3);
              break;

          case 11: // +CPPP####### -> CCC (PP) ###-####
              country = value[0];
              city = value.slice(1, 4);
              number = value.slice(4);
              break;

          case 12: // +CCCPP####### -> CCC (PP) ###-####
              country = value.slice(0, 3);
              city = value.slice(3, 5);
              number = value.slice(5);
              break;

          default:
              return tel;
      }

      if (country == 1) {
          country = "";
      }

      number = number.slice(0, 3) + '-' + number.slice(3);

      return (country + " (" + city + ") " + number).trim();
  };
});

// Components

FamilyPortal
.component('advancedForm', {
  template: require('../app/templates/components/forms/advanced-form.html'),
  controller : AdvancedFormController,
  transclude : true,
  bindings   : {
      leadId        : '<',
      advancedFormId: '<',
      preview: '<',
      formMode: '@',
      formObject: '=',
      sourceKey: '<',
      sourceTypeId: '@',
      instanceId: '=',
  }
});

FamilyPortal
.component('calculate', {
  template: require('../app/templates/components/forms/calculate.html'),
  controller: calculateFields,
  require: {
      advancedFormControl: '^advancedForm'
  },
  bindings: {
      init: '<',
      model: '<',
      facetId: '<'
  }
});

FamilyPortal
.component('fileupload', {
  template: require('../app/templates/components/forms/file-upload.html'),
  controller: fileUpload,

  bindings: {
      guid: '=',
      leadId: '<',
      isEditor: '@',
      init: '<',
      model: '<',
      status: '<'
  }
});

FamilyPortal.component('signature', {
  template: require('../app/templates/components/forms/advanced-form-signature.html'),
  controller: advancedFormSignature,

  bindings: {
      init: '<',
      model: '<',
      show: '<',
      sig: '<',
      width: '=',
      height: '=',
      color: '<',
      bgColor: '<',
      lineWidth: '<',
      cssClass: '<',
      blankData: '<'
  }
});

FamilyPortal.
component('allevaLoader', {
  template: require('../app/templates/loaders/skcube.html')
});

FamilyPortal.
component('aboutMe', {
  template: require('../app/views/Dashboard_Cards/about-me.html'),
  bindings: {
    user: '=', // pass model object
  },
});

FamilyPortal.
component('upcomingSessions', {
  template: require('../app/views/Dashboard_Cards/upcoming-sessions.html'),
  bindings: {
    sessions: '=', // pass model object
  },
});

FamilyPortal.
component('courses', {
  template: require('../app/views/Dashboard_Cards/courses.html'),
  bindings: {
    coursedata: '=', // pass model object
  },
});

FamilyPortal.
component('pagination', {
  template: require('../app/templates/components/pagination/paginate-controls.html'),
  bindings: {
      model: '=', // pass model object
      items: '=' // total number of items
  },
  controller : PaginationController
});

FamilyPortal.
  component('allevaBtnPrimary', {
    template: require('../app/templates/components/buttons/alleva-btn-primary.html'),
    bindings: {
        title: '@',
        disabled: '=',
        click: '&',
        btntype: '@',
    },
    controllerAs: 'ctrl'
  });

  FamilyPortal.
  component('allevaBtnPrimaryAlt', {
    template: require('../app/templates/components/buttons/alleva-btn-primary-alt.html'),
    bindings: {
        title: '@',
        disabled: '=',
        click: '&',
        btntype: '@',
        iconclass: '@',
    },
    controllerAs: 'ctrl'
  });

  FamilyPortal.
  component('allevaBtnSelectable', {
    template: require('../app/templates/components/buttons/alleva-btn-selectable.html'),
    bindings: {
        title: '@',
        disabled: '=',
        selected: '=',
        click: '&',
        btntype: '@',
        iconclass: '@',
    },
    controllerAs: 'ctrl'
  });

  FamilyPortal.
  component('allevaBtnBordered', {
    template: require('../app/templates/components/buttons/alleva-btn-bordered.html'),
    bindings: {
      title: '@',
      disabled: '=',
      click: '&',
      btntype: '@',
      iconclass: '@',
    },
    controllerAs: 'ctrl'
  });

  FamilyPortal.
  component('allevaBtnBorderedAlt', {
    template: require('../app/templates/components/buttons/alleva-btn-bordered-alt.html'),
    bindings: {
      title: '@',
      disabled: '=',
      click: '&',
      btntype: '@',
      iconclass: '@',
    },
    controllerAs: 'ctrl'
  });

  FamilyPortal.
  component('allevaInputPrimary', {
    template: require('../app/templates/components/inputs/alleva-input-primary.html'),
    bindings: {
        name: '@',
        type: '@',
        autocomplete: '@',
        value: '@',
        disabled: '=',
        alert: '@', // invalid data alert text
        form: '=', // pass form component is attached to
        model: '=', // pass model object
        showinvalid: '=' // force invalid class to show
    },
  });

  FamilyPortal.
  component('customForm', {
    template: require('../app/templates/components/forms/custom-form.html'),
    controller: FormController,
    bindings: {
      parent: '=',
    },
  });

  FamilyPortal.
  component('treatmentPlan', {
    template: require('../app/templates/components/treatment-plan/treatment-plan.html'),
    controller: TreatmentPlanController,
    bindings: {
      parent: '=',
    },
  });

  FamilyPortal.
  component('treatmentPlanReview', {
    template: require('../app/templates/components/treatment-plan/treatment-plan-review.html'),
    controller: TreatmentPlanController,
    bindings: {
      parent: '=',
    },
  });

  FamilyPortal.
  component('tprDischargePlanning', {
    template: require('../app/templates/components/treatment-plan/tpr-discharge-planning.html'),
    controller: TreatmentPlanController,
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprDemographics', {
    template: require('../app/templates/components/treatment-plan/tpr-demographics.html'),
    bindings: {
      user: '=',
      form: '=',
    },
  });

  FamilyPortal.
  component('tprMasterProblem', {
    template: require('../app/templates/components/treatment-plan/tpr-master-problem.html'),
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprSnag', {
    template: require('../app/templates/components/treatment-plan/tpr-snag.html'),
    controller: TreatmentPlanController,
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprSummaryProgress', {
    template: require('../app/templates/components/treatment-plan/tpr-summary-progress.html'),
    controller: TreatmentPlanController,
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprJustificationTreatment', {
    template: require('../app/templates/components/treatment-plan/tpr-justification-treatment.html'),
    controller: TreatmentPlanController,
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprReviewNote', {
    template: require('../app/templates/components/treatment-plan/tpr-review-note.html'),
    controller: TreatmentPlanController,
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprNextReviewDue', {
    template: require('../app/templates/components/treatment-plan/tpr-next-review-due.html'),
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprCustomForms', {
    template: require('../app/templates/components/treatment-plan/tpr-custom-forms.html'),
    bindings: {
      section: '=',
    },
  });

  FamilyPortal.
  component('tprMedications', {
    template: require('../app/templates/components/treatment-plan/tpr-medications.html'),
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('tprMasterTreatmentPlan', {
    template: require('../app/templates/components/treatment-plan/tpr-master-treatment-plan.html'),
    bindings: {
      form: '=',
    },
  });

  FamilyPortal.
  component('treatmentPlanSection', {
    template: require('../app/templates/components/treatment-plan/treatment-plan-section.html'),
    bindings: {
      tp: '=',
    },
  });

FamilyPortal.filter('utcToLocal', function () {
  return function (utcDateString, offset, format) {
      var isDST             = moment().isDST();
      let off               = isDST ? (offset + 60) : offset;
      let offsetAbs         = Math.abs(off);
      let offsetTime        = (off < 0 ? "-" : "+") + ("00" + Math.floor(offsetAbs / 60)).slice(-2) + ("00" + (offsetAbs % 60)).slice(-2);

      if (!format) {
          format = "MM/DD/YYYY";
      }
      // return if input date is null or undefined
      if (!utcDateString) {
          return;
      }

      // // if _LoggedUserDetails doesn't exist, use local timezone
      if (!offset) {
          return moment.utc(utcDateString).local().format(format);
      }

      // convert and format date using the built in angularjs date filter
      var offsetDate = moment.utc(utcDateString).utcOffset(offsetTime).format(format);

      return offsetDate;
  };
});


FamilyPortal.config(routes);

FamilyPortal.constant('moment', moment);
FamilyPortal.constant('formio', Formio);
FamilyPortal.constant('FroalaEditor', FroalaEditor);
FamilyPortal.constant('dayjs', dayjs);

// Services
FamilyPortal.service('logService', logService);
FamilyPortal.service('siteStyle', siteStyle);
FamilyPortal.service('gClassroom', gClassroom);
FamilyPortal.service('allevaAutosave', allevaAutosave);
FamilyPortal.service('darkDeploy', darkDeploy);
FamilyPortal.service('fileUpload', fileUpload);
FamilyPortal.service('calculateFields', calculateFields);

// controller for main page/menu bar
FamilyPortal.controller('FamilyPortalApp', FamilyPortalApp);
FamilyPortal.controller('LoginController', LoginController);
FamilyPortal.controller('ResetPasswordController', ResetPasswordController);
FamilyPortal.controller('MyCalendarController', MyCalendarController);
FamilyPortal.controller('HomeController', HomeController);
FamilyPortal.controller('FormIOController', FormIOController);
FamilyPortal.controller('FormController', FormController);
FamilyPortal.controller('TreatmentPlanController', TreatmentPlanController);
FamilyPortal.controller('InboxController', InboxController);
FamilyPortal.controller('PaginationController', PaginationController);
FamilyPortal.controller('AdvancedFormController', AdvancedFormController);

FamilyPortal.directive('compileHtml',  ['$compile', function ($compile) {
  // The directive factory creates a link function.
  return function (scope, element, attrs) {
      scope.$watch(
          function (scope) {
              // Watch the 'compile' expression for changes.
              return scope.$eval(attrs.compileHtml);
          },
          function (value) {
              // When the 'compile' expression changes
              // assign it into the current DOM.
              element.html(value);

              // Compile the new DOM and link it to the current scope.
              // NOTE: we only compile '.childNodes' so that we
              // don't get into an infinite loop compiling ourselves.
              $compile(element.contents())(scope);
          }
      );
  };
}]);


FamilyPortal.directive('datePicker', function () {
  return {
      restrict: 'A',
      require: 'ngModel',
       link: function (scope, element, attrs, ngModelCtrl) {
         var format = attrs.format || 'mm/dd/yyyy';
          $(element).datepicker({autoclose: true, todayHighlight: true, format: format});
          $(element).on("changeDate", function (ev) {
            scope.$apply(function () {
              ngModelCtrl.$setViewValue(ev.format(format));
           });
        });


      }
  };
});

FamilyPortal.directive('bTimepicker', function () {
  return {
      restrict: 'AC',
      link: function (scope, el, attr) {
          $(el).timepicker({
              showInputs: false,
          });//can add more default option here
          //attr.$observe('defaulttime', function (data) {
          //    el.timepicker('setTime', attr.defaulttime);
          //}, true);
      }
  };
});

FamilyPortal.directive('httpSrc', [
  '$http', function ($http) {
      var directive = {
          link: link,
          restrict: 'A'
      };
      return directive;

      function link(scope, element, attrs) {
          var requestConfig = {
              method: 'Get',
              url: attrs.httpSrc,
              secure: true,
              responseType: 'arraybuffer',
              cache: 'true'
          };

          $http(requestConfig)
              .then(function(response) {
                const extension = (attrs.src || attrs.httpSrc).split(/[#?]/)[0].split('.').pop().trim();
                const mimeType = MIME_TYPES[extension] || 'application/octet-stream';
                var blob = new Blob([response.data], { type: mimeType })
                var urlCreator = window.URL || window.webkitURL;
                var fileUrl = urlCreator.createObjectURL(blob);
                attrs.$set('href', fileUrl);
                attrs.$set('src', fileUrl);
              });
      }

  }
]);

const uploadedFileSize = (1024 * 1024 * 200); //200 mb
const validFileExtensions_v2 = ["jpg", "jpeg", "bmp", "gif", "png", "mp4", "mpeg", "mkv", "xls", "xlsx",
"pdf", "doc", "docx", "csv", "mp3", "wav", ".xt", "avi", "asf", "mov", "AVCHD", "flv", "mpg", "WMV", "DivX", "H.264"];

// TODO: refactor
FamilyPortal.directive("fileUploader", function () {
  return {
      restrict: "A",
      link: function (scope, element, attrs) {
          element.bind("change", function () {
              scope.$apply(function () {
                  var itr = 0;
                  var length = element[0].files.length;

                  if (!scope.$ctrl.queuedForUpload) {
                      scope.$ctrl.queuedForUpload = [];
                  }

                  if (!scope.$ctrl.uploadDocumentList) {
                      scope.$ctrl.uploadDocumentList = [];
                  }

                  // Check for upto 3 file.
                  if ((length + scope.$ctrl.queuedForUpload.length) > 3) {


                      toastr.warning("Upload document limit exceeded (Maximum 3 files).", "Warning");
                      scope.IsUploading = false;
                      return false;
                  }

                  let files = [];  // Array to store files
                  for (var i = 0; i < element[0].files.length; i++) {
                      files.push(element[0].files[i]);
                  }

                  for (var i = 0; i < length; i++) {
                      var file = files[i];

                      var extension = file.name.split('.').pop().toLowerCase(),  // File extension from input file.
                          isValidFile = validFileExtensions_v2.indexOf(extension) > -1,  // Is extension in acceptable types.
                          sFileSize = file.size;

                      // Check for valid format.
                      if (!isValidFile) {
                          toastr.warning("Please upload a valid document.", "Warning");
                          scope.IsUploading = false;
                          return false;
                      }

                      // Check for valid size.
                      if (sFileSize > uploadedFileSize) {
                          toastr.warning("Upload document size exceeded allowed limits (Maximum 200MB).", "Warning");
                          scope.IsUploading = false;
                          return false;
                      }
                  }


                  for (var i = 0; i < length; i++) {
                      scope.IsUploading = true;
                      var file = files[i];
                      var fileReader = new FileReader();

                      var fileObject = null;
                      fileReader.onload = function (event) {
                          var fileName = files[itr].name;

                          fileObject = {
                              Base64String: event.target.result
                                  .replace("data:", "")
                                  .replace(/^.+,/, ""),
                              Name: fileName,
                              Status: "Queued" // "Reading"
                          };
                          scope.$ctrl.uploadDocumentList.push(fileObject);

                          itr = itr + 1;
                          if (length === itr) {
                              scope.IsUploading = false;
                          }
                      };

                      fileReader.readAsDataURL(file);
                      element[0].value = null;
                  }
              });
          });
      }
  }
});

//add verification token for all the $http requests
FamilyPortal.factory('httpRequestInterceptor',
['$localStorage', function ($localStorage) {
    return {
        request: function (config) {

            // use this to destroying other existing headers
            //config.headers = { 'Authorization': $localStorage.access_token }
            // use this to prevent destroying other existing headers
            if($localStorage.access_token && config.secure){
              config.headers.Authorization = 'Bearer ' + $localStorage.access_token;
            }
            return config;
        }
    };
}]);
FamilyPortal.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('httpRequestInterceptor');
}]);

const MIME_TYPES = {
    'abs':           'audio/x-mpeg',
    'ai':            'application/postscript',
    'aif':           'audio/x-aiff',
    'aifc':          'audio/x-aiff',
    'aiff':          'audio/x-aiff',
    'aim':           'application/x-aim',
    'art':           'image/x-jg',
    'asf':           'video/x-ms-asf',
    'asx':           'video/x-ms-asf',
    'au':            'audio/basic',
    'avi':           'video/x-msvideo',
    'avx':           'video/x-rad-screenplay',
    'bcpio':         'application/x-bcpio',
    'bin':           'application/octet-stream',
    'bmp':           'image/bmp',
    'body':          'text/html',
    'cdf':           'application/x-cdf',
    'cer':           'application/pkix-cert',
    'class':         'application/java',
    'cpio':          'application/x-cpio',
    'csh':           'application/x-csh',
    'css':           'text/css',
    'dib':           'image/bmp',
    'doc':           'application/msword',
    'dtd':           'application/xml-dtd',
    'dv':            'video/x-dv',
    'dvi':           'application/x-dvi',
    'eot':           'application/vnd.ms-fontobject',
    'eps':           'application/postscript',
    'etx':           'text/x-setext',
    'exe':           'application/octet-stream',
    'gif':           'image/gif',
    'gtar':          'application/x-gtar',
    'gz':            'application/x-gzip',
    'hdf':           'application/x-hdf',
    'hqx':           'application/mac-binhex40',
    'htc':           'text/x-component',
    'htm':           'text/html',
    'html':          'text/html',
    'ief':           'image/ief',
    'jad':           'text/vnd.sun.j2me.app-descriptor',
    'jar':           'application/java-archive',
    'java':          'text/x-java-source',
    'jnlp':          'application/x-java-jnlp-file',
    'jpe':           'image/jpeg',
    'jpeg':          'image/jpeg',
    'jpg':           'image/jpeg',
    'js':            'application/javascript',
    'jsf':           'text/plain',
    'json':          'application/json',
    'jspf':          'text/plain',
    'kar':           'audio/midi',
    'latex':         'application/x-latex',
    'm3u':           'audio/x-mpegurl',
    'mac':           'image/x-macpaint',
    'man':           'text/troff',
    'mathml':        'application/mathml+xml',
    'me':            'text/troff',
    'mid':           'audio/midi',
    'midi':          'audio/midi',
    'mif':           'application/x-mif',
    'mov':           'video/quicktime',
    'movie':         'video/x-sgi-movie',
    'mp1':           'audio/mpeg',
    'mp2':           'audio/mpeg',
    'mp3':           'audio/mpeg',
    'mp4':           'video/mp4',
    'mpa':           'audio/mpeg',
    'mpe':           'video/mpeg',
    'mpeg':          'video/mpeg',
    'mpega':         'audio/x-mpeg',
    'mpg':           'video/mpeg',
    'mpv2':          'video/mpeg2',
    'ms':            'application/x-wais-source',
    'nc':            'application/x-netcdf',
    'oda':           'application/oda',
    'odb':           'application/vnd.oasis.opendocument.database',
    'odc':           'application/vnd.oasis.opendocument.chart',
    'odf':           'application/vnd.oasis.opendocument.formula',
    'odg':           'application/vnd.oasis.opendocument.graphics',
    'odi':           'application/vnd.oasis.opendocument.image',
    'odm':           'application/vnd.oasis.opendocument.text-master',
    'odp':           'application/vnd.oasis.opendocument.presentation',
    'ods':           'application/vnd.oasis.opendocument.spreadsheet',
    'odt':           'application/vnd.oasis.opendocument.text',
    'otg':           'application/vnd.oasis.opendocument.graphics-template',
    'oth':           'application/vnd.oasis.opendocument.text-web',
    'otp':           'application/vnd.oasis.opendocument.presentation-template',
    'ots':           'application/vnd.oasis.opendocument.spreadsheet-template',
    'ott':           'application/vnd.oasis.opendocument.text-template',
    'ogx':           'application/ogg',
    'ogv':           'video/ogg',
    'oga':           'audio/ogg',
    'ogg':           'audio/ogg',
    'otf':           'application/x-font-opentype',
    'spx':           'audio/ogg',
    'flac':          'audio/flac',
    'anx':           'application/annodex',
    'axa':           'audio/annodex',
    'axv':           'video/annodex',
    'xspf':          'application/xspf+xml',
    'pbm':           'image/x-portable-bitmap',
    'pct':           'image/pict',
    'pdf':           'application/pdf',
    'pgm':           'image/x-portable-graymap',
    'pic':           'image/pict',
    'pict':          'image/pict',
    'pls':           'audio/x-scpls',
    'png':           'image/png',
    'pnm':           'image/x-portable-anymap',
    'pnt':           'image/x-macpaint',
    'ppm':           'image/x-portable-pixmap',
    'ppt':           'application/vnd.ms-powerpoint',
    'pps':           'application/vnd.ms-powerpoint',
    'ps':            'application/postscript',
    'psd':           'image/vnd.adobe.photoshop',
    'qt':            'video/quicktime',
    'qti':           'image/x-quicktime',
    'qtif':          'image/x-quicktime',
    'ras':           'image/x-cmu-raster',
    'rdf':           'application/rdf+xml',
    'rgb':           'image/x-rgb',
    'rm':            'application/vnd.rn-realmedia',
    'roff':          'text/troff',
    'rtf':           'application/rtf',
    'rtx':           'text/richtext',
    'sfnt':          'application/font-sfnt',
    'sh':            'application/x-sh',
    'shar':          'application/x-shar',
    'sit':           'application/x-stuffit',
    'snd':           'audio/basic',
    'src':           'application/x-wais-source',
    'sv4cpio':       'application/x-sv4cpio',
    'sv4crc':        'application/x-sv4crc',
    'svg':           'image/svg+xml',
    'svgz':          'image/svg+xml',
    'swf':           'application/x-shockwave-flash',
    't':             'text/troff',
    'tar':           'application/x-tar',
    'tcl':           'application/x-tcl',
    'tex':           'application/x-tex',
    'texi':          'application/x-texinfo',
    'texinfo':       'application/x-texinfo',
    'tif':           'image/tiff',
    'tiff':          'image/tiff',
    'tr':            'text/troff',
    'tsv':           'text/tab-separated-values',
    'ttf':           'application/x-font-ttf',
    'txt':           'text/plain',
    'ulw':           'audio/basic',
    'ustar':         'application/x-ustar',
    'vxml':          'application/voicexml+xml',
    'xbm':           'image/x-xbitmap',
    'xht':           'application/xhtml+xml',
    'xhtml':         'application/xhtml+xml',
    'xls':           'application/vnd.ms-excel',
    'xml':           'application/xml',
    'xpm':           'image/x-xpixmap',
    'xsl':           'application/xml',
    'xslt':          'application/xslt+xml',
    'xul':           'application/vnd.mozilla.xul+xml',
    'xwd':           'image/x-xwindowdump',
    'vsd':           'application/vnd.visio',
    'wav':           'audio/x-wav',
    'wbmp':          'image/vnd.wap.wbmp',
    'wml':           'text/vnd.wap.wml',
    'wmlc':          'application/vnd.wap.wmlc',
    'wmls':          'text/vnd.wap.wmlsc',
    'wmlscriptc':    'application/vnd.wap.wmlscriptc',
    'wmv':           'video/x-ms-wmv',
    'woff':          'application/font-woff',
    'woff2':         'application/font-woff2',
    'wrl':           'model/vrml',
    'wspolicy':      'application/wspolicy+xml',
    'z':             'application/x-compress',
    'zip':           'application/zip'
  };

//End Here
